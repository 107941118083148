import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import awsExports from '../aws-exports';
import { fetchAuthSession } from 'aws-amplify/auth';

const URL_AUTH = awsExports.aws_appsync_graphqlEndpoint;
const API_KEY = awsExports.aws_appsync_apiKey;

const authLink = setContext(async (_, prevContext) => {
  const session = await fetchAuthSession();
  const accessToken = session?.tokens?.accessToken.toString();

  const context = {
    ...prevContext,
    headers: { ...prevContext.headers },
  };

  if (!accessToken) {
    context.headers['X-API-KEY'] = API_KEY;
  } else {
    context.headers['authorization'] = accessToken;
  }

  return context;
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
};

const apolloClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({
      uri: URL_AUTH,
    })
  ),
  cache: new InMemoryCache(),
  defaultOptions,
});

export { apolloClient };
