import { useEffect, useMemo } from 'react';

import Button from '../../components/Button/Button';
import Spinner from '../../components/Spinner/Spinner';
import { useAuth } from '../../contexts/Auth';
import { useGetCurrentUser } from '../../hooks/queries';
import StringUtils from '../../utils/StringUtils';
import { Block, Wrapper } from './styled';

type AvailableApplication = {
  id: string;
  label: string;
  handler: () => void;
};

export function Home() {
  const { user } = useAuth();
  const { data: userApplicationsData, isLoading } = useGetCurrentUser({
    additionalOptions: { enabled: !!user },
  });

  const handleNavigateToApp = (application: string) => () =>
    (window.location.href = `/${application}`);

  const availableApplications: AvailableApplication[] = useMemo(() => {
    const userApplications = userApplicationsData?.getCurrentUser?.applications as string[];

    return userApplications?.map((application: string) => ({
      id: application,
      label: StringUtils.firstChartUppercase(application),
      handler: handleNavigateToApp(application),
    }));
  }, [userApplicationsData]);

  useEffect(() => {
    const userConfig = localStorage.getItem('userConfig');
    const parsedConfig = JSON.parse(userConfig || '{}');

    if (parsedConfig) {
      parsedConfig['userApplications'] = userApplicationsData?.getCurrentUser?.applications || [];
      const userConfigStr = JSON.stringify(parsedConfig);

      localStorage.setItem('userConfig', userConfigStr);
    }
  }, [userApplicationsData?.getCurrentUser?.applications]);

  if (isLoading) {
    return (
      <Wrapper>
        <Spinner variant="primary" />
      </Wrapper>
    );
  }

  if (!availableApplications?.length) {
    return (
      <Wrapper>
        <Block>Invalid user configuration</Block>
      </Wrapper>
    );
  }

  if (availableApplications.length === 1) {
    availableApplications[0].handler();
    return null;
  }

  return (
    <Wrapper>
      <Block>
        {availableApplications.map(({ id, label, handler }) => (
          <Button
            key={id}
            block
            onClick={handler}
          >
            {label}
          </Button>
        ))}
      </Block>
    </Wrapper>
  );
}
