import React, { useRef, useState } from 'react';
import Button from '../../components/Button/Button';
import { AlertStyled, Buttons, Container, NoData, Table, Wrapper } from './style';
import Spinner from '../../components/Spinner/Spinner';
import { RiDeleteBinLine, RiEditBoxLine, RiSave2Line } from 'react-icons/ri';
import moment from 'moment';
import CreateApiKeyModal from '../../components/Modal/CreateApiKeyModal/CreateApiKeyModal';
import { useGetApiKeyList } from '../../hooks/queries';
import { useDeleteApiKey, useUpdateApiKey } from '../../hooks/mutations';
import get from 'lodash/get';
import FormControl from '../../components/Forms/FormControl/FormControl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UpdateApiKeyNameMutationVariables } from '../../API';

type FormVariables = UpdateApiKeyNameMutationVariables;

const updateSchema = yup.object().shape({
  name: yup.string().min(1).required('Name is required'),
});

const ApiKeys: React.FC = () => {
  const [isCreateApiKeyModalOpen, setIsCreateApiKeyModalOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string>();
  const [editingId, setEditingId] = useState<string>();
  const currentDeletingId = useRef<string | undefined>();

  const { data, isLoading, refetch } = useGetApiKeyList();
  const { mutateAsync: deleteApiKey, isLoading: loadingDeleteApiKey, error } = useDeleteApiKey();
  const {
    mutateAsync: updateApiKey,
    isLoading: loadingUpdateApiKey,
    error: updateError,
  } = useUpdateApiKey();

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
  } = useForm<FormVariables>({
    resolver: yupResolver(updateSchema),
  });

  const handleDeleteApiKey = async (id: string) => {
    currentDeletingId.current = id;
    await deleteApiKey({ id });
    refetch();
  };

  const handleCreateApiKey = () => {
    setIsCreateApiKeyModalOpen(true);
  };

  const handleApiKeyCreated = (apiKey: string) => {
    setSuccessMsg(
      `API key was successfully created, please copy & save it in secure storage: ${apiKey}`
    );
    refetch();
  };

  const handleSave = async () => {
    handleSubmit(async () => {
      clearErrors();
      const { name } = getValues();
      await updateApiKey({ id: editingId!, name }).catch(console.error);
      refetch();
      setEditingId(undefined);
    })();
  };

  const handleEdit = (id: string) => {
    setEditingId(id);
    setValue('name', data?.listApiKeys.find((key) => key.id === id)!.name ?? '');
  };

  if (isLoading) {
    return (
      <Wrapper>
        <Spinner variant="primary" />
      </Wrapper>
    );
  }

  return (
    <>
      <CreateApiKeyModal
        open={isCreateApiKeyModalOpen}
        closeModalFunc={() => setIsCreateApiKeyModalOpen(false)}
        onSuccess={handleApiKeyCreated}
      />

      <div>
        <Button
          onClick={handleCreateApiKey}
          variant="standard"
        >
          Create API key
        </Button>
        {successMsg && <AlertStyled variant="success">{successMsg}</AlertStyled>}
        {Boolean(error) && <AlertStyled variant="danger">{get(error, 'message')}</AlertStyled>}
        {Boolean(updateError) && (
          <AlertStyled variant="danger">
            {get(updateError, 'message', "Failed to update the API key's name")}
          </AlertStyled>
        )}
      </div>

      <Container>
        {!isLoading && !data?.listApiKeys.length ? (
          <NoData>No API keys</NoData>
        ) : (
          <Table>
            <thead>
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  <strong>Last used date</strong>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {data?.listApiKeys.map((key) => (
                <tr key={key.id}>
                  <td>
                    {editingId === key.id ? (
                      <FormControl
                        name="name"
                        register={register}
                        label="Name"
                        id="name"
                        inputContainerStyle={{ width: '90%' }}
                        type="text"
                        error={errors.name?.message}
                      />
                    ) : (
                      key.name
                    )}
                  </td>
                  <td>{key.lastUsed ? moment(key.lastUsed).format('MM/DD/YYYY') : 'Not used'}</td>
                  <td>
                    <Buttons>
                      {editingId === key.id ? (
                        <Button
                          onClick={() => handleSave()}
                          loading={loadingUpdateApiKey}
                          variant="standard"
                          shape="round"
                        >
                          <RiSave2Line />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleEdit(key.id)}
                          variant="standard"
                          shape="round"
                        >
                          <RiEditBoxLine />
                        </Button>
                      )}
                      <Button
                        onClick={() => handleDeleteApiKey(key.id)}
                        loading={loadingDeleteApiKey && currentDeletingId.current === key.id}
                        variant="danger"
                        shape="round"
                      >
                        <RiDeleteBinLine />
                      </Button>
                    </Buttons>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </>
  );
};

export default ApiKeys;
