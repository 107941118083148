import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../components/Button/Button';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';

import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordRequirements from '../../components/PasswordRequirements/PasswordRequirements';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import errorMessages from '../../config/errorMessages';
import { useAuth } from '../../contexts/Auth';
import { Subtitle, Title } from './style';

type Inputs = {
  newPassword: string;
  confirmNewPassword: string;
  username: string;
};

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required(errorMessages.required)
    .min(8, errorMessages.minCharacters(8))
    .matches(/[a-z]/g, 'Password must have lowercase characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/g, 'Password must have symbol characters')
    .matches(/[A-Z]/g, 'Password must have uppercase characters')
    .matches(/[0-9]/g, 'Password must have numeric characters'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], "Passwords don't match")
    .required(errorMessages.required),
});

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { confirmSignInWithPassword } = useAuth();
  const [searchParams] = useSearchParams({});
  const username = useMemo(() => getUsernameFromURL(), []);
  const password = useMemo(() => searchParams.get('password'), [searchParams]);
  const [loading, setLoading] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setSideImagePath!('/images/password-lock.jpg');
    setBackgroundImagePath!('/images/earth.jpg');

    if (!username || !password) {
      navigate(-1);
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = async ({ newPassword }) => {
    try {
      setLoading(true);
      const response = await confirmSignInWithPassword(username!, password!, newPassword);

      if (response.isSignedIn) {
        navigate('/', {
          replace: true,
        });
      } else {
        setError('newPassword', {
          type: 'custom',
          message: 'Incorrect email or password',
        });
      }
    } catch (e) {
      setError('newPassword', {
        type: 'custom',
        message: 'Something went wrong',
      });
    } finally {
      setLoading(false);
    }
  };

  function getUsernameFromURL() {
    const windowSearch = window.location.search;
    const pattern = /(username=)(.*)(?=&)/;
    const matches = { ...windowSearch.match(pattern) };
    const username = matches[2];

    return username || null;
  }

  return (
    <>
      <Title>Create Password</Title>
      <Subtitle>
        Create a password for the user <strong>{username}</strong>
      </Subtitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormControl
            label="New Password"
            id="newPassword"
            type="password"
            name="newPassword"
            register={register}
            error={errors.newPassword?.message}
            onFocus={() => setShowRequirements(true)}
            onBlur={() => setShowRequirements(false)}
            dropdown={
              showRequirements ? <PasswordRequirements password={watch('newPassword')} /> : null
            }
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            label="Confirm New Password"
            id="confirmNewPassword"
            type="password"
            name="confirmNewPassword"
            register={register}
            error={errors.confirmNewPassword?.message}
          />
        </FormGroup>
        <FormGroup>
          <Button
            loading={loading}
            type="submit"
          >
            Create Password
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default Register;
