import React, { useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { AuthenticateUserQueryVariables } from '../../API';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Forms/Checkbox/Checkbox';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import { useUnprotectedLayout } from '../../components/UnprotectedRouteLayout/UnprotectedRouteLayout';
import errorMessages from '../../config/errorMessages';
import { SignInInput, useAuth } from '../../contexts/Auth';
import { AlertContainer, ForgotPasswordMsg, FormBottom } from './style';
import { getIdpQuery } from '../../hooks/queries';
import Alert from '../../components/Alert/Alert';

enum STEP {
  ENTER_EMAIL = 'ENTER_EMAIL',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  REQUEST_TOTP = 'REQUEST_TOTP',
}

const schema = yup.object().shape({
  email: yup.string().email(errorMessages.email).required(errorMessages.required),
  password: yup.string().required(errorMessages.required),
});

const schemaOnlyEmail = yup.object().shape({
  email: yup.string().email(errorMessages.email).required(errorMessages.required),
});

const Login: React.FC = () => {
  const { signIn, respondWithTotp, signInExternal } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const authError = useMemo(() => searchParams.get('error_description'), [searchParams]);

  const formRef = useRef<HTMLFormElement>(null);

  const { setBackgroundImagePath, setSideImagePath } = useUnprotectedLayout();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<STEP>(STEP.ENTER_EMAIL);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<AuthenticateUserQueryVariables>({
    resolver: yupResolver(step === STEP.ENTER_EMAIL ? schemaOnlyEmail : schema),
  });

  useEffect(() => {
    setSideImagePath!('/images/enbridge-logo-in-building.jpg');
    setBackgroundImagePath!('/images/earth.jpg');

    const wasLogout = !!localStorage.getItem('logout');
    localStorage.removeItem('logout');
    if (wasLogout) navigate(0);
  }, []);

  useEffect(() => {
    if (formRef.current) {
      const inputs = formRef.current.querySelectorAll('input');

      setTimeout(() => {
        inputs[0].focus();
      }, 1000);
    }
  }, [step]);

  const login = async (credentials: SignInInput) => {
    try {
      setLoading(true);
      const { isSignedIn, nextStep } = await signIn(credentials);

      if (isSignedIn) {
        reset();
        navigate('/', {
          replace: true,
        });
      } else {
        if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
          setStep(STEP.REQUEST_TOTP);
        } else {
          setError('email', {
            type: 'custom',
            message: 'Incorrect email or password',
          });
          setError('password', {
            type: 'custom',
            message: 'Incorrect email or password',
          });
        }
      }
    } catch (e) {
      setError('email', {
        type: 'custom',
        message: 'Something went wrong',
      });
      setError('password', {
        type: 'custom',
        message: 'Something went wrong',
      });
    } finally {
      setLoading(false);
    }
  };

  const checkTotp = async (code: string) => {
    try {
      setLoading(true);
      const { success } = await respondWithTotp(code);

      if (success) {
        reset();
        navigate('/', {
          replace: true,
        });
      } else {
        setError('challengeAnswer', {
          type: 'custom',
          message: 'Incorrect OTP code',
        });
      }
    } catch (e) {
      setError('challengeAnswer', {
        type: 'custom',
        message: 'Incorrect OTP code',
      });
    } finally {
      setLoading(false);
    }
  };

  const checkUserTypeAndContinue = async (email: string) => {
    try {
      setLoading(true);

      const [_, domain] = email.split('@');
      const result = await getIdpQuery({ domain });

      if (result.data.getIdp) {
        signInExternal(result.data.getIdp);
      } else {
        setStep(STEP.ENTER_PASSWORD);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<AuthenticateUserQueryVariables> = async (formData) => {
    switch (step) {
      case STEP.REQUEST_TOTP: {
        if (formData.challengeAnswer) {
          checkTotp(formData.challengeAnswer.replaceAll(' ', ''));
        }
        break;
      }

      case STEP.ENTER_PASSWORD: {
        login({ email: formData.email.toLowerCase(), password: formData.password });
        break;
      }

      case STEP.ENTER_EMAIL: {
        checkUserTypeAndContinue(formData.email);
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
      >
        {authError && (
          <AlertContainer>
            <Alert variant="danger">{authError}</Alert>
          </AlertContainer>
        )}
        {step === STEP.ENTER_EMAIL && (
          <FormGroup>
            <FormControl
              type="text"
              label="Email address"
              id="email"
              name="email"
              error={errors.email?.message}
              register={register}
            />
          </FormGroup>
        )}

        {step === STEP.ENTER_PASSWORD && (
          <FormGroup>
            <FormControl
              register={register}
              type="password"
              label="Password"
              id="password"
              name="password"
              error={errors.password?.message}
            />
          </FormGroup>
        )}

        {step === STEP.REQUEST_TOTP && (
          <FormGroup>
            <FormControl
              name="challengeAnswer"
              register={register}
              label="Authentication code"
              id="challengeAnswer"
              type="text"
              error={errors.challengeAnswer?.message}
            />
          </FormGroup>
        )}

        {step === STEP.ENTER_PASSWORD && (
          <FormGroup>
            <Checkbox
              label="Remember Me"
              name="rememberMe"
              register={register}
              id="rememberMe"
            />
          </FormGroup>
        )}

        <FormBottom>
          {step === STEP.ENTER_PASSWORD && (
            <ForgotPasswordMsg to="/auth/forgot">Forgot Password?</ForgotPasswordMsg>
          )}
          <Button
            loading={loading}
            type="submit"
          >
            Log In
          </Button>
        </FormBottom>
      </form>
    </>
  );
};

export default Login;
