/* eslint-disable */

// TODO(alexey.grigorovich): delete the default values for the endpoints and the API keys once all the pipelines have been updated. Current values correspond to the legacy "development" environment.
const awsmobile = {
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT ||
    'https://cunhwwjtzfhyxbixa7pri7se6i.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION || 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY || 'da2-ijz6ebgjtfde3jg72plgv4itre',
};

export default awsmobile;
