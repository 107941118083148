import { AuthSession } from 'aws-amplify/auth';
import { Role } from '../types/UserRoles';

export default class User {
  private _groups: string[] = [];
  private _isMfaEnabled: boolean;
  private _isFederated: boolean;
  private _username: string;

  constructor(session: AuthSession, isMfaEnabled: boolean = false) {
    if (!session.tokens?.accessToken.payload) return;

    const userObject = session.tokens.accessToken.payload;

    this.username = String(userObject.username);
    this._isFederated = userObject.scope?.includes('openid') ?? false;
    this._groups = (userObject['cognito:groups'] ?? []) as string[];
    this._isMfaEnabled = isMfaEnabled;
  }

  public get username(): string {
    return this._username;
  }
  public set username(value: string) {
    this._username = value;
  }

  public hasRole(roles: Role | Role[]): boolean {
    const allUserRoles = this.getRoles();

    if (typeof roles === 'string') {
      return allUserRoles.includes(roles);
    }

    if (typeof roles === 'object') {
      return roles.some((role) => allUserRoles.includes(role));
    }

    return false;
  }

  public getGroups(): string[] {
    return this._groups;
  }

  public getRoles(): Role[] {
    return this._groups.map((group) => group.split('/')[1] as Role);
  }

  public isMFAEnabled() {
    return this._isMfaEnabled;
  }

  public isFederated() {
    return this._isFederated;
  }

  public setIsMFAEnabled(isMFAEnabled: boolean) {
    return (this._isMfaEnabled = isMFAEnabled);
  }

  public isAdmin(): boolean {
    return this.getRoles().includes('admin');
  }
}
