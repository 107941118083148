import { gql } from '@apollo/client';

export const enableMfa = gql`
  mutation EnableMfa($password: String!) {
    enableMfa(password: $password) {
      success
      message
      __typename
    }
  }
`;

export const disableMfa = gql`
  mutation DisableMfa($challengeAnswer: String!) {
    disableMfa(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

export const forgotPassword = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) {
      success
      __typename
    }
  }
`;

export const confirmForgotPassword = gql`
  mutation ConfirmForgotPassword($code: String!, $username: String!, $password: String!) {
    confirmForgotPassword(code: $code, username: $username, password: $password) {
      success
      __typename
    }
  }
`;

export const createApiKey = gql`
  mutation CreateApiKey($name: String!) {
    createApiKey(name: $name) {
      success
      apiKey
      __typename
    }
  }
`;

export const updateApiKey = gql`
  mutation UpdateApiKeyName($id: String!, $name: String!) {
    updateApiKeyName(id: $id, name: $name) {
      success
      __typename
    }
  }
`;

export const deleteApiKey = gql`
  mutation DeleteApiKey($id: String!) {
    deleteApiKey(id: $id) {
      success
      message
      __typename
    }
  }
`;

export const enableIdp = gql`
  mutation EnableIdp($providerName: String!) {
    enableIdp(providerName: $providerName) {
      success
      message
      __typename
    }
  }
`;

export const disableIdp = gql`
  mutation DisableIdp($providerName: String!) {
    disableIdp(providerName: $providerName) {
      success
      message
      __typename
    }
  }
`;
