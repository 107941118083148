import { ResourcesConfig } from '@aws-amplify/core';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: String(process.env.REACT_APP_COGNITO_POOL_ID),
      userPoolClientId: String(process.env.REACT_APP_COGNITO_CLIENT_ID),
      identityPoolId: String(process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID),
      allowGuestAccess: false,
      signUpVerificationMethod: 'code',
      loginWith: {
        email: true,
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN
            ? String(process.env.REACT_APP_OAUTH_DOMAIN)
            : 'csdevmi.auth.us-east-1.amazoncognito.com',
          scopes: [],
          redirectSignIn: [
            process.env.REACT_APP_OAUTH_CALLBACK_URL
              ? String(process.env.REACT_APP_OAUTH_CALLBACK_URL)
              : 'http://localhost:3000/auth/login',
          ],
          redirectSignOut: [
            process.env.REACT_APP_LOGOUT_CALLBACK_URL
              ? String(process.env.REACT_APP_LOGOUT_CALLBACK_URL)
              : 'http://localhost:3000/auth/login',
          ],
          responseType: 'code',
          providers: [],
        },
      },
    },
  },
};

export default awsConfig;
