import { DocumentNode, OperationVariables } from '@apollo/client';
import { QueryObserverOptions, QueryOptions, useMutation } from 'react-query';
import {
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables,
  DisableMfaMutation,
  DisableMfaMutationVariables,
  EnableMfaMutation,
  EnableMfaMutationVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables,
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables,
  EnableIdpMutation,
  EnableIdpMutationVariables,
  DisableIdpMutation,
  DisableIdpMutationVariables,
  UpdateApiKeyNameMutation,
  UpdateApiKeyNameMutationVariables,
} from '../API';
import { apiClient } from '../api-client';
import { useAuth } from '../contexts/Auth';
import {
  confirmForgotPassword,
  createApiKey,
  deleteApiKey,
  disableIdp,
  disableMfa,
  enableIdp,
  enableMfa,
  forgotPassword,
  updateApiKey,
} from '../graphql/mutations';

type CreateCustomMutationParams = {
  mutation: DocumentNode;
};

type CustomMutationParams<TQuery, TVariables = any> = {
  variables?: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
};

type BaseMutationParams<TQuery, TVariables = any> = {
  mutation: DocumentNode;
  variables: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
  additionalKeys?: any[];
};

function useBaseMutation<TQuery, TVariables extends OperationVariables>({
  mutation,
}: BaseMutationParams<TQuery, TVariables>) {
  const { logout } = useAuth();

  return useMutation(mutateFunc);

  async function mutateFunc(variables: TVariables) {
    try {
      const { data } = await apiClient.mutate<TVariables, TQuery>(mutation, {
        ...variables,
      });

      return data;
    } catch (e: unknown) {
      throw apiClient.errorHandle(e, logout);
    }
  }
}

export const useConfirmForgotPass = createCustomMutation<
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables
>({ mutation: confirmForgotPassword });

export const useForgotPassword = createCustomMutation<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>({ mutation: forgotPassword });

export const useDisableMfa = createCustomMutation<DisableMfaMutation, DisableMfaMutationVariables>({
  mutation: disableMfa,
});

export const useEnableMfa = createCustomMutation<EnableMfaMutation, EnableMfaMutationVariables>({
  mutation: enableMfa,
});

function createCustomMutation<TMutation, TVariables extends OperationVariables>({
  mutation,
}: CreateCustomMutationParams) {
  return ({ variables, additionalOptions }: CustomMutationParams<TMutation, TVariables> = {}) =>
    useBaseMutation<TMutation, TVariables>({
      mutation,
      variables: { ...variables! },
      additionalOptions,
    });
}

export const useCreateApiKey = createCustomMutation<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>({ mutation: createApiKey });

export const useUpdateApiKey = createCustomMutation<
  UpdateApiKeyNameMutation,
  UpdateApiKeyNameMutationVariables
>({ mutation: updateApiKey });

export const useDeleteApiKey = createCustomMutation<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>({ mutation: deleteApiKey });

export const useEnableIdp = createCustomMutation<EnableIdpMutation, EnableIdpMutationVariables>({
  mutation: enableIdp,
});

export const useDisableIdp = createCustomMutation<DisableIdpMutation, DisableIdpMutationVariables>({
  mutation: disableIdp,
});
