import styled from 'styled-components';
import Button from '../../components/Button/Button';
import breakpoints from '../../styles/breakpoints';
import Alert from '../../components/Alert/Alert';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  font-size: 14px;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;

  table {
    width: 100%;
  }

  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  td {
    padding: 15px 0;
    width: 50%;
    border-bottom: 1px solid #ccc;
  }
`;

export const ContainerSubtitle = styled.h2`
  color: black;
`;

export const FloatingButtons = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: flex;

  @media screen and (max-width: ${(props) => breakpoints.lg}) {
    padding: 7px 15px;
  }
`;

export const FloatingButtonItem = styled(Button)`
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 16px 24px;
  font-size: 16px;
  line-height: 32px;
  margin-left: 8px;

  @media screen and (max-width: ${(props) => breakpoints.lg}) {
    padding: 7px 15px;
  }
`;

export const DragDropContainer = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px dashed ${(props) => props.theme.colors.gray};
  padding: 30px 15px;
  text-align: center;
  cursor: pointer;
`;

export const DragDropMsg = styled.span`
  line-height: 32px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray};
`;

export const Image = styled.img`
  width: 165px;
  height: auto;
`;

export const Table = styled.table`
  color: ${(props) => props.theme.colors.black};
`;

export const AlertStyled = styled(Alert)`
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-direction: column;
`;

export const NoData = styled.div`
  padding: 30px 0px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
