import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import Header from '../Header/Header';
import { NavTabItem } from '../NavTabs/NavTabs';
import { useAuth } from '../../contexts/Auth';
import { useEffect, useMemo } from 'react';

const AssetsLayout = () => {
  const { refactoredUser } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabs: NavTabItem[] = useMemo(() => {
    const tabs: NavTabItem[] = [];

    if (refactoredUser) {
      if (refactoredUser.isFederated()) {
        tabs.push({
          to: '/administration/api-keys',
          text: 'API Keys',
        });
      } else {
        tabs.push({
          to: '/administration/my-settings',
          text: 'My Settings',
        });
      }

      if (refactoredUser.isAdmin()) {
        tabs.push({
          to: '/administration/providers',
          text: 'Identity providers',
        });
      }
    }
    return tabs;
  }, [refactoredUser]);

  useEffect(() => {
    if (tabs.length > 0 && pathname === '/administration') {
      navigate(tabs[0].to);
    }
  }, [tabs, pathname]);

  return (
    <>
      <Header
        title="Administration"
        navTabItems={tabs}
      />
      <Outlet />
    </>
  );
};

export default AssetsLayout;
