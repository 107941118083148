import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button/Button';

import { Contact, Container, Message } from './style';

const LoginError: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const authError = useMemo(
    () => searchParams.get('error_description') ?? searchParams.get('error'),
    [searchParams]
  );

  return (
    <Container>
      <Message>{authError ?? 'Unexpected authorization error'}</Message>
      <Contact>Please contact administrator</Contact>
      <Button onClick={() => navigate('/auth/login')}>Login Page</Button>
    </Container>
  );
};

export default LoginError;
