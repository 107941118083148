import { useEffect, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import { Role } from '../../types/UserRoles';

type AuthorizedProps = {
  role: Role | Role[];
};

const AuthorizedRoute: React.FC<PropsWithChildren<AuthorizedProps>> = ({ role, children }) => {
  const { refactoredUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (refactoredUser && !refactoredUser.hasRole(role)) {
      navigate(-1);
    }
  });

  if (!refactoredUser) return null;

  if (refactoredUser.hasRole(role)) return <>{children}</>;

  return null;
};

export default AuthorizedRoute;
