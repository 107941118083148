import { gql } from '@apollo/client';

export const authenticateUser = gql`
  query AuthenticateUser(
    $email: String!
    $password: String!
    $newPassword: String
    $challengeAnswer: String
    $session: String
  ) {
    authenticateUser(
      email: $email
      password: $password
      newPassword: $newPassword
      challengeAnswer: $challengeAnswer
      session: $session
    )
  }
`;

export const verifyTotp = gql`
  query VerifyTotp($challengeAnswer: String!) {
    verifyTotp(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

export const getAssociatedSecretCode = gql`
  query GetAssociatedSecretCode($password: String!) {
    getAssociatedSecretCode(password: $password) {
      secretCode
      __typename
    }
  }
`;

export const getCurrentUserQuery = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      username
      email
      applications
    }
  }
`;

export const listApiKeysQuery = gql`
  query ListApiKeys {
    listApiKeys {
      id
      name
      lastUsed
    }
  }
`;

export const getIdp = gql`
  query GetIdp($domain: String!) {
    getIdp(domain: $domain)
  }
`;

export const listIdp = gql`
  query ListIdp {
    listIdp {
      providerName
      enabled
    }
  }
`;
