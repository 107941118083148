import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getCurrentUser,
  signOut,
  fetchAuthSession,
  fetchMFAPreference,
  confirmSignIn,
  AuthUser,
  signIn as cognitoSignIn,
  signInWithRedirect,
  AuthSession,
  SignInOutput,
  ConfirmSignInOutput,
} from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import User from '../services/User';
import UserSettingsUtils from '../utils/UserSettingsUtils';
import { useTheme } from './Theme';
import awsConfig from '../aws-config';

type SignInReturn = { success: boolean; nextStep?: string };
export type SignInInput = { email: string; password: string; newPassword?: string };

type AuthCtx = {
  refactoredUser?: User;
  signed: boolean;
  user?: AuthUser;
  session?: AuthSession;
  loading: boolean;
  signIn: (credentials: SignInInput) => Promise<SignInOutput>;
  signInExternal: (provider: string) => Promise<void>;
  respondWithTotp: (code: string) => Promise<SignInReturn>;
  confirmSignInWithPassword: (
    username: string,
    tempPassword: string,
    newPassword: string
  ) => Promise<ConfirmSignInOutput>;
  logout: () => void;
};

Amplify.configure(awsConfig);

const AuthContext = createContext<AuthCtx | null>(null);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { setTheme } = useTheme() as any;
  const [user, setUser] = useState<AuthUser>();
  const [refactoredUser, setRefactoredUser] = useState<User>();
  const [session, setSession] = useState<AuthSession>();

  const initSession = async () => {
    setLoading(true);
    try {
      const session = await fetchAuthSession();
      const user = await getCurrentUser();
      const mfaSettings = await fetchMFAPreference();

      setRefactoredUser(new User(session, mfaSettings.enabled?.includes('TOTP') ?? false));
      setUser(user);
      setSession(session);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const afterLogout = useCallback(async () => {
    localStorage.clear();
    localStorage.setItem('logout', 'true');
    UserSettingsUtils.clearSettings();
    setUser(undefined);
    setSession(undefined);
    setTheme('light');
    setRefactoredUser(undefined);
  }, [setTheme]);

  const logout = useCallback(async () => {
    setLoading(true);
    await signOut();
  }, []);

  useEffect(() => {
    return Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signedOut') {
        afterLogout();
        if (!refactoredUser?.isFederated()) {
          setLoading(false);
        }
      }
    });
  }, [afterLogout, refactoredUser]);

  const respondWithTotp = useCallback(async (code: string) => {
    const { isSignedIn } = await confirmSignIn({ challengeResponse: code });

    if (isSignedIn) {
      initSession();
    }

    return { success: isSignedIn };
  }, []);

  const signInExternal = useCallback((provider: string) => {
    return signInWithRedirect({ provider: { custom: provider } });
  }, []);

  const signIn = useCallback(async (credentials: SignInInput) => {
    await signOut();

    const response = await cognitoSignIn({
      username: credentials.email,
      password: credentials.password,
    });
    const { nextStep } = response;

    switch (nextStep.signInStep) {
      case 'DONE': {
        initSession();
        break;
      }
    }

    return response;
  }, []);

  const confirmSignInWithPassword = useCallback(
    async (username: string, tempPassword: string, newPassword: string) => {
      const { nextStep } = await signIn({ email: username, password: tempPassword });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        const response = await confirmSignIn({
          challengeResponse: newPassword,
        });
        const { isSignedIn } = response;

        if (isSignedIn) {
          return response;
        } else {
          throw new Error('Username or temporary password is incorrect');
        }
      } else {
        throw new Error('Incorrect sign in step');
      }
    },
    []
  );

  useEffect(() => {
    initSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        session,
        signed: !!session,
        refactoredUser,
        loading,
        signInExternal,
        signIn,
        respondWithTotp,
        confirmSignInWithPassword,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext) as AuthCtx;

export default AuthProvider;
