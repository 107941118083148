import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineQrCode } from 'react-icons/md';
import QRCode from 'react-qr-code';
import * as yup from 'yup';
import {
  EnableMfaMutationVariables,
  GetAssociatedSecretCodeQueryVariables,
  VerifyTotpQueryVariables,
} from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Modal from '../Modal';
import { Description } from '../style';
import { GenerateQrCodeButton, QrCodeContainer } from './style';
import { getSecretCode } from '../../../hooks/queries';
import { useEnableTotp } from '../../../hooks';

type FormVariables = EnableMfaMutationVariables &
  GetAssociatedSecretCodeQueryVariables &
  VerifyTotpQueryVariables;

const enableMfaSchema = yup.object().shape({
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type EnableMfaModalProps = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
};

const EnableMfaModal: React.FC<EnableMfaModalProps> = ({ open, closeModalFunc }) => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormVariables>({
    resolver: yupResolver(enableMfaSchema),
  });

  const { refactoredUser } = useAuth();
  const { enableTotp } = useEnableTotp();

  const [successMsg, setSuccessMsg] = useState('');
  const [secretCode, setSecretCode] = useState<string | null>(null);
  const [loadingSecretCode, setLoadingSecretCode] = useState(false);
  const [loadingEnableMfa, setLoadingEnableMfa] = useState(false);

  // Generates a secret code to be used for TOTP
  const handleGenerateTotpQrCode = async () => {
    setLoadingSecretCode(true);
    clearErrors();
    setSecretCode(null);

    try {
      const { password } = getValues();

      if (!password) {
        throw 'Invalid password';
      }

      const { data } = await getSecretCode({ password });

      setSecretCode(data.getAssociatedSecretCode.secretCode);
    } catch (error) {
      setLoadingSecretCode(false);

      console.error(error);
      setError('password', {
        type: 'custom',
        message: 'Invalid password',
      });
    }

    setLoadingSecretCode(false);
  };

  // Enables MFA on the logged in account
  const handleEnableMfa = async () => {
    setLoadingEnableMfa(true);
    clearErrors();

    try {
      const { password, challengeAnswer } = getValues();

      if (!password) {
        throw 'Invalid password';
      }

      if (!challengeAnswer) {
        throw 'Invalid TOTP';
      }

      // Enable TOTP
      // If it fails, it will throw
      const { success } = await enableTotp(password, challengeAnswer.replaceAll(' ', ''));

      if (success) {
        refactoredUser!.setIsMFAEnabled(true);
        closeModalFunc(false);
        setSuccessMsg('MFA Enabled');
        setSecretCode(null);
        setValue('password', '');
        setValue('challengeAnswer', '');
      }
    } catch (error) {
      if (error === 'Invalid password') {
        setError('password', {
          type: 'custom',
          message: 'Invalid password',
        });
      }
      if (error === 'Invalid TOTP') {
        setError('challengeAnswer', {
          type: 'custom',
          message: 'Invalid code',
        });
      }
    }

    setLoadingEnableMfa(false);
  };

  useEffect(() => {
    if (!open) setSuccessMsg('');
  }, [open]);

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Enable MFA"
      description="Please enter your password and authentication code to enable MFA."
      buttonText="Enable MFA"
      buttonDisabled={loadingEnableMfa}
      onSubmitBtnClick={() => handleEnableMfa()}
      buttonLoading={loadingEnableMfa}
    >
      <FormGroup>
        <FormControl
          name="password"
          register={register}
          label="Password"
          id="password"
          type="password"
          error={errors.password?.message}
        />
      </FormGroup>

      <Description>
        Generate a QR code and use it with your authenticator app to receive an authentication code.
      </Description>

      <QrCodeContainer>
        {secretCode && (
          <QRCode
            value={`otpauth://totp/${refactoredUser?.username}?secret=${secretCode}&issuer=BrightLync`}
            size={128}
            viewBox={`0 0 128 128`}
          />
        )}

        <GenerateQrCodeButton
          startIcon={<MdOutlineQrCode size={14} />}
          variant="light-primary"
          onClick={() => handleGenerateTotpQrCode()}
          loading={loadingSecretCode}
          disabled={loadingSecretCode}
        >
          Generate QR Code
        </GenerateQrCodeButton>
      </QrCodeContainer>

      <FormGroup>
        <FormControl
          name="challengeAnswer"
          register={register}
          label="Authentication code"
          id="challengeAnswer"
          type="text"
          error={errors.challengeAnswer?.message}
        />
      </FormGroup>

      {successMsg && <Alert variant="success">{successMsg}</Alert>}
    </Modal>
  );
};

export default EnableMfaModal;
