import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button/Button';
import {
  AlertStyled,
  Container,
  Disabled,
  Enabled,
  NoData,
  Table,
  TdCenter,
  Wrapper,
} from './style';
import Spinner from '../../components/Spinner/Spinner';
import { RiStopFill, RiPlayFill } from 'react-icons/ri';
import { useIdpListQuery } from '../../hooks/queries';
import { useDisableIdp, useEnableIdp } from '../../hooks/mutations';
import get from 'lodash/get';

const IdentityProviders: React.FC = () => {
  const [successMsg, setSuccessMsg] = useState<string>();
  const currentUpdatingProvider = useRef<string | undefined>();

  const { data, isLoading, refetch } = useIdpListQuery();
  const {
    mutateAsync: enableIdp,
    isLoading: loadingEnableIdp,
    error: errorEnable,
  } = useEnableIdp();
  const {
    mutateAsync: disableIdp,
    isLoading: loadingDisableIdp,
    error: errorDisable,
  } = useDisableIdp();

  const isUpdating = loadingEnableIdp || loadingDisableIdp;
  const updatingError = errorEnable ?? errorDisable;

  const handleDisableIdp = async (providerName: string) => {
    currentUpdatingProvider.current = providerName;
    await disableIdp({ providerName });
    setSuccessMsg(`IDP ${providerName} was successfully disabled`);
    refetch();
  };

  const handleEnableIdp = async (providerName: string) => {
    currentUpdatingProvider.current = providerName;
    await enableIdp({ providerName });
    setSuccessMsg(`IDP ${providerName} was successfully enabled`);
    refetch();
  };

  useEffect(() => {
    if (successMsg) {
      const timeout = setTimeout(() => setSuccessMsg(undefined), 3000);
      return () => clearTimeout(timeout);
    }
  }, [successMsg]);

  if (isLoading) {
    return (
      <Wrapper>
        <Spinner variant="primary" />
      </Wrapper>
    );
  }

  return (
    <>
      <div>
        {successMsg && <AlertStyled variant="success">{successMsg}</AlertStyled>}
        {Boolean(updatingError) && (
          <AlertStyled variant="danger">{get(updatingError, 'message')}</AlertStyled>
        )}
      </div>

      <Container>
        {!isLoading && !data?.listIdp?.length ? (
          <NoData>No IDP providers</NoData>
        ) : (
          <Table>
            <thead>
              <tr>
                <td>
                  <strong>Name</strong>
                </td>
                <td>
                  <strong>Domains</strong>
                </td>
                <td>
                  <strong>Status</strong>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {(data?.listIdp ?? []).map((provider) => (
                <tr key={provider.providerName}>
                  <td>{provider.providerName}</td>
                  <td>n/a</td>
                  <td>
                    {provider.enabled ? <Enabled>Enabled</Enabled> : <Disabled>Disabled</Disabled>}
                  </td>
                  <TdCenter>
                    {provider.enabled ? (
                      <Button
                        onClick={() =>
                          provider.providerName && handleDisableIdp(provider.providerName)
                        }
                        loading={
                          isUpdating && currentUpdatingProvider.current === provider.providerName
                        }
                        disabled={isUpdating}
                        variant="danger"
                        shape="round"
                      >
                        <RiStopFill />
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          provider.providerName && handleEnableIdp(provider.providerName)
                        }
                        loading={
                          isUpdating && currentUpdatingProvider.current === provider.providerName
                        }
                        disabled={isUpdating}
                        variant="standard"
                        shape="round"
                      >
                        <RiPlayFill />
                      </Button>
                    )}
                  </TdCenter>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </>
  );
};

export default IdentityProviders;
