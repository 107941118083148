import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import errorMessages from '../../../config/errorMessages';
import { useCreateApiKey } from '../../../hooks/mutations';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Modal from '../Modal';
import get from 'lodash/get';
import { CreateApiKeyMutationVariables } from '../../../API';

type FormVariables = CreateApiKeyMutationVariables;

const disableMfaSchema = yup.object().shape({
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type Props = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
  onSuccess: (apiKey: string) => void;
};

const CreateApiKeyModal: React.FC<Props> = ({ open, closeModalFunc, onSuccess }) => {
  const {
    register,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    reset,
  } = useForm<FormVariables>({
    resolver: yupResolver(disableMfaSchema),
  });
  const [errorMsg, setErrorMsg] = useState<string>();

  const { mutateAsync: createApiKey, isLoading } = useCreateApiKey();

  const handleDisableMfa = async () => {
    clearErrors();
    setErrorMsg(undefined);

    try {
      const { name } = getValues();

      if (!name) {
        setError('name', {
          type: 'custom',
          message: 'Empty name',
        });
        return;
      }

      const data = await createApiKey({ name });

      if (data?.createApiKey.success && data?.createApiKey.apiKey) {
        closeModalFunc(false);
        onSuccess(data?.createApiKey.apiKey);
      } else {
        setErrorMsg('Cannot create API key, please contact support');
      }
    } catch (error) {
      setErrorMsg(get(error, 'message', 'Unknown error'));
    }
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Create API key"
      description="Please enter API key name."
      buttonText="Create"
      buttonDisabled={isLoading}
      onSubmitBtnClick={() => handleDisableMfa()}
      buttonLoading={isLoading}
    >
      <FormGroup>
        <FormControl
          name="name"
          register={register}
          label="Name"
          id="name"
          type="text"
          error={errors.name?.message}
        />
      </FormGroup>

      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
    </Modal>
  );
};

export default CreateApiKeyModal;
